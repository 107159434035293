// creat react component
import React from "react"
import { Link } from "gatsby"
import { useTranslation } from "gatsby-plugin-react-i18next";



// create component
const EventItemComponent = ({ timeStart, timeEnd, typeEvent, routeName, slug, title, dayView = false, gridColOrder = 0, overlappingEvent = false, recapEvent = false, numberEvents = 1, onClickWeekDay, startHourNumber, nbIntervalHour, nbMinPerInterval, isSeveralDate = false, interneExterne = "interne" }) => {
    const gridCol = getGridCol(dayView, gridColOrder, timeStart, timeEnd, overlappingEvent, recapEvent, isSeveralDate);
    const gridSpan = Math.floor((timeEnd.getUTCHours() - timeStart.getUTCHours()) * nbIntervalHour + (timeEnd.getMinutes() - timeStart.getMinutes()) / nbMinPerInterval);
    const gridRow = getGridRow(timeStart, gridSpan, recapEvent, startHourNumber, nbIntervalHour, nbMinPerInterval, isSeveralDate);
    const durationEvent = Math.floor((timeEnd.getUTCHours() - timeStart.getUTCHours()) * (nbIntervalHour * nbMinPerInterval) + (timeEnd.getMinutes() - timeStart.getMinutes()));
    const maxLine = Math.floor(((durationEvent / nbMinPerInterval) / 1.33));

    const { t, i18n } = useTranslation();

    var dateFormatted = new Date(timeStart).toLocaleDateString(i18n.language, {
        day: "numeric",
        weekday: "long",
        month: "long",
        year: "numeric",
        timeZone: "UTC"
    }) + " • " + new Date(timeStart).toLocaleTimeString(i18n.language, {
        hour: "numeric",
        minute: "numeric",
        timeZone: "UTC"
    }) + " - " + new Date(timeEnd).toLocaleTimeString(i18n.language, {
        hour: "numeric",
        minute: "numeric",
        timeZone: "UTC"
    })

    if (isSeveralDate)
        dateFormatted = new Date(timeStart).toLocaleDateString(i18n.language, {
            day: "numeric",
            weekday: "long",
            month: "long",
            year: "numeric",
            timeZone: "UTC"
        }) + " - " + new Date(timeEnd).toLocaleDateString(i18n.language, {
            day: "numeric",
            weekday: "long",
            month: "long",
            year: "numeric",
            timeZone: "UTC"
        }) + " • " + new Date(timeStart).toLocaleTimeString(i18n.language, {
            hour: "numeric",
            minute: "numeric",
            timeZone: "UTC"
        }) + " - " + new Date(timeEnd).toLocaleTimeString(i18n.language, {
            hour: "numeric",
            minute: "numeric",
            timeZone: "UTC"
        })

    if (recapEvent) {
        return (
            <div tabindex="0" className={`recap-event event-background bg-${interneExterne == "externe" ? "externe" : "interne"} font-semibold text-orange-nr`} onClick={() => onClickWeekDay(new Date(timeStart).getDay() - 1)} style={{ gridColumn: gridCol, gridRow: gridRow }}>
                <div className={`text-${interneExterne == "externe" ? "interne" : "white"}`}>
                    +{numberEvents}
                </div>
            </div>
        );
    }

    if (isSeveralDate) {
        return (
            <Link to={`/event/${slug}`} className={`event event-background bg-${interneExterne == "externe" ? "externe" : "interne"} mt-2 mb-2`} style={{ gridColumn: gridCol, gridRow: gridRow }}>
                <div className={`title-event hi text-${interneExterne == "externe" ? "interne" : "orange-nr"}`} style={{ WebkitLineClamp: 1 }}>
                    <span className="visually-hidden" >{dateFormatted}</span>
                    {title}
                </div>
            </Link>
        );
    }
    return (

        <Link to={`/event/${slug}`} className={`event event-background bg-${interneExterne == "externe" ? "externe" : "interne"}`} style={{ gridColumn: gridCol, gridRow: gridRow }} data-tooltip-id="tooltip-main" data-tooltip-content={title}>
            
                <div className={`title-event hi text-${interneExterne == "externe" ? "interne" : "orange-nr"}`} style={{ WebkitLineClamp: maxLine }}>
                    <span className="visually-hidden" >{dateFormatted}</span>
                    {title}
                </div>
                {
                    durationEvent > 30 &&
                    <div>
                        <div className="chip-event my-1">
                            {typeEvent}
                        </div>
                        <div className={`text-${interneExterne == "externe" ? "interne" : "white"}`}>
                            {routeName}
                        </div>
                    </div>
                }
                
        </Link>

    );
}

function getGridCol(dayView, gridColOrder, timeStart, timeEnd, overlappingEvent, recapEvent, isSeveralDate) {
    if (dayView) {
        return gridColOrder + 2;
    } else {
        if (recapEvent) { return (timeStart.getDay() * 2 + 1) + " / auto" }
        if (isSeveralDate) { return (timeStart.getDay() * 2) + " / " + (timeEnd.getDay() * 2 + 2) }
        return (timeStart.getDay() * 2) + (overlappingEvent ? " / auto" : " / span 2");
    }
}

function getGridRow(timeStart, gridSpan, recapEvent, startHourNumber, nbIntervalHour, nbMinPerInterval, isSeveralDate) {
    if (isSeveralDate) { return "1 / auto" }
    return ((((timeStart.getUTCHours() - startHourNumber) * nbIntervalHour) + 2) + Math.floor(timeStart.getMinutes() / nbMinPerInterval)) + " / span " + (recapEvent ? "2" : gridSpan);
}

export default EventItemComponent;