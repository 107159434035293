import * as React from "react"
import '../../styles/title.css'
import '../../styles/calendar.scss'
import { useTranslation } from "gatsby-plugin-react-i18next";
import EventItemComponent from "./event-item";
import EventUtils from "../../utils/event.utils";
import { ExpandMoreOutlined } from "@mui/icons-material";

import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";

function separateEvents(events) {
  var eventsLeft = [...events];
  var separatedEvents = [];
  while (eventsLeft.length != 0) {
    const generatedEventsCombinaison = EventUtils.generateNonOverlapping(eventsLeft);
    eventsLeft = eventsLeft.filter(e => !generatedEventsCombinaison.some(e2 => e2.idLiferay === e.idLiferay));
    separatedEvents.push(generatedEventsCombinaison);
  }

  return separatedEvents;
}
// create component
const DayCalendarComponent = ({ events = [], day, startHour, endHour, startDate, endDate, programDay }) => {

  const { t, i18n } = useTranslation();
  // récupération des jours de la semaine
  const days = EventUtils.getWeekList(startDate, i18n.language);
  const dayContent = programDay[day];

  // events du jour qui ne sont pas sur plusieurs jours
  events = events.filter(e => new Date(e.timeStart).getDay() == day + 1 && e.isSeveralDate != "true")
  const eventsSorted = separateEvents(events);

  // récupération des info pour l'affichage du grid
  const startHourNumber = Number(startHour.split(":")[0]);
  const endHourNumber = Number(endHour.split(":")[0]);
  const nbHours = endHourNumber - startHourNumber;
  const nbMinPerInterval = 5; // 5 min
  const nbIntervalHour = 60 / nbMinPerInterval; // nombre d'intervalle entre chaque heure (= nombre de lignes entre chaque heure) 
  const timeStep = (nbHours * nbIntervalHour) + 1; // nombre total d'intervalle (= lignes du grid)
  const intervalToShow = 60 / nbMinPerInterval; // heure affichée tous les X intervals

  // récupération des 'coordonnées' de l'heure actuelle
  const gridColCurrentTime = "2 / -1";
  const gridRowCurrentTime = ((((new Date().getHours() - startHourNumber) * nbIntervalHour) + 2) + Math.floor(new Date().getMinutes() / nbMinPerInterval));

  // récupération des 'coordonnées' de la pause méridienne
  const startNoonNumber = (12 > endHourNumber || 14 < startHourNumber) ? -1 : (12 <= startHourNumber ? 2 : ((12 - startHourNumber) * nbIntervalHour) + 2); //12H
  const endNoonNumber = (14 > startHourNumber && 14 <= endHourNumber) ? ((14 - startHourNumber) * nbIntervalHour) + 2 : -2; //14H

  const getHeaderDay = (dayContent, day) => {
    if(dayContent?.descriptionDay) {
        return (<Accordion>
          <AccordionSummary expandIcon={<ExpandMoreOutlined />} aria-controls="panel1a-content" id="panel1a-header">
            <div className="flex flex-grow items-center justify-center p-2">
              <span className="font-medium text-xl">{dayContent?.titleDay ? dayContent.titleDay : day}</span>
            </div>
          </AccordionSummary>
  
          <AccordionDetails>
            <p>
              {dayContent.descriptionDay}
            </p>
          </AccordionDetails>
        </Accordion>)
    }
    return ( <div className="p-5">
    <span className="font-medium text-xl">{dayContent?.titleDay ? dayContent.titleDay : day}</span>
  </div>);
  }

  // structure div content :
  // - fond pour la pause méridienne
  // - affichage des heures
  // - affichage des lignes séparatrices
  // - affichage des events 
  return (
    <div>
      <div className="container-calendar">
        <div className="days" style={{ gridTemplateColumns: `4em 1fr` }}>
          <div className="filler"></div>
          <div className="day">{getHeaderDay(dayContent,days[day])}</div>
        </div>
        <div className="content" style={{ gridTemplateColumns: `4em repeat(${eventsSorted.length}, 1fr)`, gridTemplateRows: `4rem repeat(${timeStep}, 1em)` }}>
          {
            startNoonNumber > -1 && <div className="bg-noon-calendar opacity-20" style={{ gridArea: startNoonNumber + " / 2 / " + endNoonNumber + " / -1" }}></div>
          }
          {
            Array.from({ length: timeStep }, (_, i) => i + 1).map((n, i) =>
            (
              <div key={i} className="time" style={{ gridRow: i + 1 }} >
                {(
                  (i % intervalToShow == 0) ?
                    ((Math.floor(i / nbIntervalHour)) + startHourNumber).toString().padStart(2, '0') + ":" + ((i % nbIntervalHour) * 5).toString().padStart(2, '0') :
                    ""
                )}
              </div>
            )
            )
          }

          <div className="filler-col"></div>

          {
            Array.from({ length: timeStep }, (_, i) => i + 1).map((n, i) =>
            (
              <div key={i} className={((i % intervalToShow == 0) ? "row" : "")} style={{ gridRow: i + 1, gridColumn: "2/-1" }}>
              </div>
            )
            )
          }

          {
            events.map((event, i) => {
              const gridColOrder = eventsSorted.findIndex(eArray => eArray.some(e => e.idLiferay === event.idLiferay));
              return (<EventItemComponent key={i} timeStart={new Date(event.timeStart)} timeEnd={new Date(event.timeEnd)} typeEvent={event.typeEvent} routeName={event.routeName} slug={event.slug} title={event.title} dayView gridColOrder={gridColOrder} startHourNumber={startHourNumber} nbIntervalHour={nbIntervalHour} nbMinPerInterval={nbMinPerInterval}   interneExterne={event.interneExterne}/>)
            }
            )
          }

        </div>
      </div>
    </div>

  )
}


export default DayCalendarComponent;