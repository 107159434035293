import * as React from "react"
import { Link } from "gatsby"
import { useTranslation } from "gatsby-plugin-react-i18next";


const ProgramDayCardComponent = ({ title, description,typeDay, link }) => {
    const { t } = useTranslation();
    const bgClassName = typeDay?.toLowerCase() == "externe" ? "bg-externe" : "bg-interne"
    const colorClassName = typeDay?.toLowerCase() == "externe" ? "text-interne" : "bg-externe"
    return (
        <div className={`flex flex-col items-center gap-5 p-6 rounded-xl ${bgClassName} relative ${colorClassName}`} >
            {typeDay?.toLowerCase() == 'interne' ? <span className="absolute bg-badge-pass text-black text-xs font-semibold top-2 left-2 py-1 px-2 transform  -rotate-12 rounded-full">PASS</span>: ''}
            <h3 className="font-light text-lg">{title}</h3>
            <div className="flex-grow min-h-titleDay">
            <span className="font-semibold text-lg text-center">{description}</span>
            </div>
            
            <Link className="underline text-sm hover:font-semibold focus:font-semibold" to={link}>Voir plus</Link>
            </div>
    )
}

export default ProgramDayCardComponent;