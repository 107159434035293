import * as React from "react"
import LinkButton from "../components/elements/LinkButton";
import LayoutSENR from "../components/layout/layout-senr"
import { useTranslation } from "gatsby-plugin-react-i18next";
import { graphql } from 'gatsby'
import SEOComponent from "../components/seo";
import TitlePageComponent from "../components/title-page";
import FilterEvent from "../components/shared/filter-events";
import DateView from "../components/shared/date-view";
import EventList from "../components/event/event-list";
import DayCalendarComponent from "../components/shared/day-calendar";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import { ExpandMoreOutlined, FilterListOutlined } from "@mui/icons-material";
import { createGlobalState } from 'react-hooks-global-state';
import ProgramDayCardComponent from "../components/shared/program-day-card";
const { useGlobalState } = createGlobalState({ selectedDay: 0 });



const ProgrammePage = ({ data }) => {
  const { t, i18n } = useTranslation();
  const siteContent = data.siteContent
  const programDay = data.programDay.nodes
  return (
    <main>
      <LayoutSENR siteContent={siteContent}>
        <SEOComponent title={t("menu_event", { year: siteContent.programYear })} />
        <TitlePageComponent title={t("menu_event", { year: siteContent.programYear })} description={siteContent.programDescription}>
        </TitlePageComponent>

        <div className="container">
          <div className={`grid grid-cols-${programDay.length} lg:grid-cols-1 lg:mx-5 gap-8`}>
            {programDay.map((day, index) => {
              const dayTitle = new Date(day.dateOfDay).toLocaleString(i18n.language, { weekday: 'long' })
              const dayTitleCapitalised = dayTitle.charAt(0).toUpperCase() + dayTitle.slice(1);
            return (<ProgramDayCardComponent key={index} title={dayTitleCapitalised} typeDay={day.typeDay} description={day.titleDay} link={`/programmation#${index}`}></ProgramDayCardComponent>)
            })}
          </div>
        </div>

      </LayoutSENR>
    </main>
  )
}
export default ProgrammePage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter:{language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
          
        }
      }
    }
    siteContent(language: {eq: $language}) {
          editionYear
          programYear
          language
          cookieURL
        personalDataURL
        programDescription
        linkPass
        dateStartWeek
        hourEnd
        mediaKit
        hourStart
        banner
    }
    programDay: allProgramDayContent(sort: {fields: dateOfDay, order: ASC},) {
      nodes {
      titleDay
      descriptionDay
      typeDay
      dateOfDay
      }
    }
  }
`
